<template>
  <div class="article-tag">
    <el-container>
      <el-header>
        <el-row :gutter="24" style="width: 100%; text-align: right">
          <el-col :span="10" style="text-align: left">
            <el-button color="#F4A460" style="color: white" @click="dialogFormVisible = true">
              <icon icon-name="Plus" icon-size="20" icon-title="创 建"></icon>
            </el-button>
          </el-col>
          <el-col :span="4"></el-col>
          <el-col :span="7">
            <el-input
              clearable
              show-word-limit
              maxlength="100"
              :prefix-icon="Search"
              v-model="search.keyword"
              placeholder="请输入关键字进行检索！"
              @input="queryTags"
              style="width: 100%"
            />
          </el-col>
          <el-col :span="3">
            <el-input-number
              v-model="search.useSize"
              :min="0"
              :max="999"
              style="width: 100%"
              placeholder="标签使用数量"
              @change="queryTags"
            />
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <el-tag
          v-for="(tag, index) in tags"
          :key="tag.serial"
          effect="plain"
          closable
          size="large"
          style="margin: 10px"
          :type="color[index % 3]"
          @close="deleteTag(tag.serial)"
        >
          {{ tag.tagName }} ({{ tag.notesCount }})
        </el-tag>
      </el-main>
    </el-container>
    <el-dialog v-model="dialogFormVisible" title="添加标签" width="20%">
      <el-input
        v-model="tagName"
        placeholder="请输入标签名称！"
        clearable
        size="large"
        show-word-limit
        maxlength="100"
      ></el-input>
      <template #footer>
        <el-button type="primary" @click="addTag">
          <icon icon-name="Select" icon-size="20" icon-title="添 加"></icon>
        </el-button>
        <el-button type="danger" @click="dialogFormVisible = false">
          <icon icon-name="CloseBold" icon-size="20" icon-title="关 闭"></icon>
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import icon from '@/components/icon'
import { createTag, deleteTag, tags } from '@/api/ArticleApi'

export default {
  name: 'ArticleTag',
  components: {
    icon
  },
  data() {
    return {
      search: {
        keyword: '',
        useSize: 99
      },
      dialogFormVisible: false,
      tagName: '',
      color: ['success', 'warning', 'danger'],
      tags: []
    }
  },
  methods: {
    queryTags() {
      tags({
        tagName: this.search.keyword,
        useSize: this.search.useSize
      }).then((res) => {
        if (res.code === 200) {
          this.tags = res.data
        }
      })
    },
    addTag() {
      createTag({
        tagName: this.tagName
      }).then((res) => {
        if (res.code === 200) {
          this.queryTags()
          this.tagName = ''
          this.dialogFormVisible = false
          this.$message({ type: 'success', message: '添加成功', duration: 1000 })
        } else {
          this.$message({ type: 'error', message: res.msg })
        }
      })
    },
    deleteTag(serial) {
      this.$confirm('是否删除选中的标签?', '警 告', {
        confirmButtonText: '删除',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(() => {
        deleteTag({
          serial: serial
        }).then((res) => {
          if (res.code === 200) {
            this.queryTags()
            this.$message({ type: 'success', message: '删除成功', duration: 1000 })
          } else {
            this.$message({ type: 'error', message: res.msg })
          }
        })
      })
    }
  },
  mounted() {
    this.queryTags()
  }
}
</script>

<style scoped>
.article-tag {
  width: 100%;
  height: 100%;
  min-width: 700px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #ffffff;
  border-radius: 5px;
}

.article-tag >>> .el-header {
  width: 100%;
  display: flex;
  align-items: center;
}

.article-tag >>> .el-main {
  height: 100px;
  min-height: 690px;
}
</style>
